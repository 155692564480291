<template>
	<h1>Maintain</h1>

	<div id="reset-flags">
		<h3>Reset Flags</h3>
		<FormButton
			:level="1"
			class="small"
			:class="{
				error: !!flagCount.artists,
				wait: waitStatus.resetFlags.artists,
			}"
			@click="resetFlags('artists')"
			:value="'Artists' + (flagCount.artists ? `: ${flagCount.artists}%` : '')"
		/>
		<FormButton
			:level="1"
			class="small"
			:class="{
				error: !!flagCount.artworks,
				wait: waitStatus.resetFlags.artworks,
			}"
			@click="resetFlags('artworks')"
			:value="'Artworks' + (flagCount.artworks ? `: ${flagCount.artworks}%` : '')"
		/>
		<FormButton
			:level="1"
			class="small"
			:class="{
				error: !!flagCount.users,
				wait: waitStatus.resetFlags.users,
			}"
			@click="resetFlags('users')"
			:value="'Users' + (flagCount.users ? `: ${flagCount.users}%` : '')"
		/>
		<FormButton
			:level="1"
			class="small"
			:class="{
				error: !!flagCount.clusters,
				wait: waitStatus.resetFlags.clusters,
			}"
			@click="resetFlags('clusters')"
			:value="'Lists & Rooms' + (flagCount.clusters ? `: ${flagCount.clusters}%` : '')"
		/>
	</div>

	<div id="actions">
		<!-- Reshuffle Welcome Page -->
		<div class="action">
			<form @submit.prevent>
				<div class="left">
					<h3>Reshuffle Welcome Page</h3>
					<p>
						Generates 10 different random indexes for the welcome page artworks.
					</p>
				</div>
				<div class="right">
					<FormButton
						:level="1"
						@click="reshuffleWelcome"
						:value="waitStatus.reshuffleWelcome ? 'Working' : 'Start'"
						:class="waitStatus.reshuffleWelcome ? 'wait' : ''"
					/>
				</div>
			</form>
		</div>

		<!-- Report Artist Artworks Discrepancies -->
		<div class="action">
			<form @submit.prevent>
				<div class="left">
					<h3>Report Artist Artworks Discrepancies</h3>
					<p>
						Inspect all artworks to make sure they're assigned to an artist.
					</p>
					<p v-if="flagCount.artworks" class="flag-warning soft small">
						Artworks flagged: {{ flagCount.artworks }}%
					</p>
				</div>
				<div class="right">
					<FormButton
						v-if="waitStatus.reportArtistArtworkDiscrepancies"
						@click="reportArtistArtworkDiscrepancies('abort')"
						value="Abort"
					/>
					<FormButton
						:level="1"
						@click="reportArtistArtworkDiscrepancies('start')"
						:class="waitStatus.reportArtistArtworkDiscrepancies ? 'wait' : ''"
						:value="
							progressStatus.reportArtistArtworkDiscrepancies
								? progressStatus.reportArtistArtworkDiscrepancies + '%'
								: 'Report'
						"
					/>
				</div>
			</form>
			<p class="result"><span></span><ActionClose /></p>
		</div>
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormButton from '@/components/FormButton'
import ActionClose from '@/components/ActionClose'

// Internal
import { prettyNr, titlefy } from '@/use/StringMagic'
import { injectMetaData } from '@/use/MetaData'
import flash from '@/use/FlashAlert'

export default {
	name: 'AdminMaintain',
	components: {
		FormButton,
		ActionClose,
	},
	setup() {
		injectMetaData({
			title: 'Admin: Maintain',
		})
		const apiStore = useApiStore()
		const adminMaintainApi = apiStore.loadApi('adminMaintain')
		return { adminMaintainApi }
	},
	data() {
		return {
			flagCount: {
				artists: '',
				artworks: '',
				users: '',
				clusters: '',
			},
			// Below the objects onto which we keep track of
			// wait status (boolean), progress (%) and timeouts
			waitStatus: {
				resetFlags: {},
			},
			progressStatus: {},
			timeouts: {},
		}
	},
	mounted() {
		this.getFlagCount()
	},
	methods: {
		// Get flag count
		async getFlagCount() {
			const { data } = await this.adminMaintainApi.getFlagCounts()
			this.flagCount = data
		},

		// Reset Flags
		async resetFlags(entityType) {
			this.waitStatus.resetFlags[entityType] = true
			let { modifiedCount } = (await this.adminMaintainApi.resetFlags(entityType)).data
			this.waitStatus.resetFlags[entityType] = false
			const message = `${prettyNr(modifiedCount, this.$imperial)} flags reset.`
			flash(message, {
				title: `Reset ${titlefy(entityType)} Flags`,
				persistent: true,
				type: 'success',
			})
			this.flagCount[entityType] = 0
		},

		// Reshuffle Welcome Page
		async reshuffleWelcome() {
			this.waitStatus.reshuffleWelcome = true
			const { modifiedCount } = (await this.adminMaintainApi.reshuffleWelcome()).data
			this.waitStatus.reshuffleWelcome = false
			const message = `Random indices renewed for ${prettyNr(
				modifiedCount,
				this.$imperial
			)} endorsed artworks`
			flash(message, {
				title: 'Reshuffle Welcome Page',
				persistent: true,
				type: 'success',
			})
		},

		// Report Artist Artworks Discrepancies
		async reportArtistArtworkDiscrepancies(signal) {
			if (signal == 'start' || signal == 'progress') {
				// Start report
				this.waitStatus.reportArtistArtworkDiscrepancies = true
				const { data } = await this.adminMaintainApi.reportArtistArtworkDiscrepancies(signal)
				if (data.progress !== undefined) {
					// Show progress while < 100
					this.progressStatus.reportArtistArtworkDiscrepancies = data.progress
					this.timeouts.reportArtistArtworkDiscrepancies = setTimeout(() => {
						this.reportArtistArtworkDiscrepancies('progress')
					}, 500)
				} else {
					// When progress is 100%, report is sent
					_handleReport.bind(this)(data)
				}
			} else if (signal == 'abort') {
				// Abort
				clearTimeout(this.timeouts.reportArtistArtworkDiscrepancies)
				const { data } = await this.adminMaintainApi.reportArtistArtworkDiscrepancies(signal)
				_handleReport.bind(this)(data)
			}

			// Process & display report
			function _handleReport(report) {
				console.log(report)
				this.waitStatus.reportArtistArtworkDiscrepancies = false
				delete this.progressStatus.reportArtistArtworkDiscrepancies
				this.getFlagCount()
				let reportMessage = ''
				let type
				if (!Object.keys(report).length) {
					// All A-OK!
					reportMessage = 'No discrepancies found.'
					type = 'success'
				} else {
					// Construct report display message
					for (const id in report) {
						reportMessage =
							reportMessage +
							`<br><b><a target="_blank" href="/admin/inspect/${id}/discrepancies">${id}</a></b><br>`
						if (report[id].orphans)
							reportMessage +=
								'› ' + report[id].orphans.length + ' Orphans: ' + report[id].orphans.join(', ') + '<br>'
						if (report[id].ghosts)
							reportMessage +=
								'› ' + report[id].ghosts.length + ' Ghosts: ' + report[id].ghosts.join(', ') + '<br>'
					}
					reportMessage = '<small>' + reportMessage + '</small>'
					type = 'error'
				}

				// Display report
				flash(reportMessage, {
					title: 'Artist-Artworks Discrepancy Report',
					persistent: true,
					type: type,
					html: true,
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/* Inspect entity */
#inspect-entity {
	margin-bottom: 0.3rem;
}

/* Reset flags */
#reset-flags {
	font-size: 0;
	margin-bottom: 0.4rem;
}
#reset-flags button {
	margin-right: 0.05rem;
}

/* Actions structure */
#actions .action {
	max-width: 9.6rem;
	padding: 0.2rem;
	border: solid 0.01rem $black-10;
}
#actions .action:not(:last-child) {
	border-bottom: none;
}
#actions form {
	display: flex;
}

/* Left: text */
#actions .left {
	flex: 1;
	padding-right: 0.2rem;
}
#actions .left p {
	margin-bottom: 0;
}
#actions .flag-warning {
	margin-top: 0.1rem;
}

/* Right: button */
#actions .right {
	flex: 0 01rem;
	display: flex;
	align-items: right;
}
#actions .right button {
	width: 1rem;
}
#actions .right button:not([type='submit']) {
	margin-right: 0.1rem;
}

/* Result */
#actions .result {
	background: $good-soft;
	border-radius: $br;
	padding: 0.2rem;
	margin: 0.1rem 0 0 0;
	position: relative;
	display: none;
}
#actions .result.err {
	background: $bad-soft;
}
#actions .result.err h3 {
	color: $bad;
}
#actions .result .x {
	position: absolute;
	top: 0;
	right: 0;
}
</style>
